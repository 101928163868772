import React, { useState, useEffect } from 'react';

function Masthead() {
  const [bannerClass, setBannerClass] = useState('translate-y-16');

  useEffect(() => {
    setTimeout(() => {
      setBannerClass('translate-y-0');
    }, 300);
  });

  return (
    <div className="text-center bg-emerald-500 pt-24 md:pt-32 w-full px-4 shadow-xl">
      <h1 className="text-3xl sm:text-5xl font-bold leading-snug text-white py-5 pb-5">Comprehensive technology solutions</h1>
      <p className="text-lg px-8 text-white pb-8">We provide business solutions for websites, cloud, data storage, mobile, email and many more services...</p>

      <img src="brand.svg" alt="logos" width={250} height={103} className="mx-auto w-full max-w-[250px] opacity-50 transition duration-300 ease-in-out hover:opacity-100 pb-14" />

      <picture>
        <source type="image/webp" srcSet="banner.webp" />
        <source type="image/png" srcSet="banner.png" />
        <img src="banner.png" alt="hero" width={672} height={252} className={`mx-auto max-w-full sm:max-w-2xl rounded-t-xl rounded-tr-xl transition duration-300 ease-in-out ${bannerClass}`} />
      </picture>
    </div>
  );
}

export default Masthead;
