/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function Pricing() {
  return (
    <section id="contact" className="relative pt-12 z-10 bg-white">
      <div className="absolute top-0 left-0 z-[-1] h-1/2 w-full lg:h-[45%] xl:h-1/2" />
      <div className="container px-8">
        <div className="-mx-4 flex flex-wrap items-center justify-center">
          <div className="w-full px-4 lg:w-7/12 xl:w-8/12">
            <div className="ud-contact-content-wrapper">
              <div className="ud-contact-title mb-12 lg:mb-[150px] text-center">
                <span className="mb-5 text-base font-semibold text-dark text-green-600">
                  CONTACT US
                </span>
                <div className="text-lg pt-1 text-gray-800">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 inline-block mr-1 mb-0.5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" />
                  </svg>
                  <a href="mailto:info@bytehost.me">
                    info@bytehost.me
                  </a>
                </div>
                {/* <h2 className="text-[35px] font-semibold">
                  Let's talk about
                  {' '}
                  <br />
                  Love to hear from you!
                </h2> */}
              </div>
              {/* <div className="mb-8 flex flex-wrap justify-between lg:mb-0">
                <div className="mb-8 flex w-[330px] max-w-full">
                  <div className="mr-6 text-[32px] text-primary">
                    <svg width="34" height="25" viewBox="0 0 34 25" className="fill-current">
                      <path d="M30.5156 0.960938H3.17188C1.42188 0.960938 0 2.38281 0 4.13281V20.9219C0 22.6719 1.42188 24.0938 3.17188 24.0938H30.5156C32.2656 24.0938 33.6875 22.6719 33.6875 20.9219V4.13281C33.6875 2.38281 32.2656 0.960938 30.5156 0.960938ZM30.5156 2.875C30.7891 2.875 31.0078 2.92969 31.2266 3.09375L17.6094 11.3516C17.1172 11.625 16.5703 11.625 16.0781 11.3516L2.46094 3.09375C2.67969 2.98438 2.89844 2.875 3.17188 2.875H30.5156ZM30.5156 22.125H3.17188C2.51562 22.125 1.91406 21.5781 1.91406 20.8672V5.00781L15.0391 12.9922C15.5859 13.3203 16.1875 13.4844 16.7891 13.4844C17.3906 13.4844 17.9922 13.3203 18.5391 12.9922L31.6641 5.00781V20.8672C31.7734 21.5781 31.1719 22.125 30.5156 22.125Z" />
                    </svg>
                  </div>
                  <div>
                    <h5 className="mb-4 text-lg font-semibold">How Can We Help?</h5>
                    <p className="text-base text-body-color"><a href="mailto:admin@bytehost.me">admin@bytehost.me</a></p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="w-full px-4 lg:w-5/12 xl:w-4/12">
            <div className="wow fadeInUp rounded-lg bg-white py-10 px-8 shadow-testimonial sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px]">
              <h3 className="mb-8 text-2xl font-semibold md:text-[26px]">
                Send us a Message
              </h3>
              <form>
                <div className="mb-6">
                  <label htmlFor="fullName" className="block text-xs text-dark">
                    Full Name*
                    <input type="text" name="fullName" placeholder="John Doe" className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                  </label>
                </div>
                <div className="mb-6">
                  <label htmlFor="email" className="block text-xs text-dark">
                    Email*
                    <input type="email" name="email" placeholder="you@example.com" className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                  </label>
                </div>
                <div className="mb-6">
                  <label htmlFor="phone" className="block text-xs text-dark">
                    Phone*
                    <input type="text" name="phone" placeholder="+44 XXXX XXXXXX" className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none" />
                  </label>
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="block text-xs text-dark">
                    Message*
                    <textarea name="message" rows="1" placeholder="Type your message here..." className="w-full resize-none border-0 border-b border-[#f1f1f1] py-10 focus:border-primary focus:outline-none" />
                  </label>
                </div>
                <div className="mb-0">
                  <button type="submit" className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
