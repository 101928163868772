import React from 'react';
import { Helmet } from 'react-helmet';
// import About from './components/About';
import Masthead from './components/Masthead';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
// import Testimonials from './components/Testimonials';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Comprehensive Technology Solutions | ByteHost</title>
        <meta name="description" content="We provide technology business solutions for websites, cloud, data storage, mobile, email and many more..." />
        <meta property="og:title" content="The Rock" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://bytehost.me/" />
        <meta property="og:image" content="https://bytehost.me/ogBanner.svg" />
      </Helmet>
      <Navbar />
      <Masthead />
      <Contact />
    </>
  );
}

export default App;
