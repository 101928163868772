/* eslint-disable max-len */
import React from 'react';

// function Dropdown() {
//   return (
//     <div className="absolute right-10 top-16 z-10 mt-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none {dropdownHidden}" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
//       <div className="py-1" role="none">
//         <a href="#d" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-0">Account settings</a>
//         <a href="#d" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1">Support</a>
//         <a href="#d" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-2">License</a>
//       </div>
//     </div>
//   );
// }

function Navbar() {
  // const cross = 'M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5';
  // const burger = 'M6 18L18 6M6 6l12 12';

  // const [icon, setIcon] = useState(cross);
  // const [showDrop, setShowDrop] = useState(false);

  // function toggleNav() {
  //   if (icon === cross) {
  //     setIcon(burger);
  //     setShowDrop(true);
  //   } else {
  //     setIcon(cross);
  //     setShowDrop(false);
  //   }
  // }

  return (
    <div>
      <div className="md:px-10 px-4 py-10 absolute min-w-full flex">
        <div className="container md:px-20">
          <div className="flex justify-between text-center">
            <img src="Logo2.svg" alt="ByteHost" className="max-h-12 md:max-h-16 m-auto" />

            {/* <button onClick={toggleNav} type="button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d={icon} />
              </svg>
            </button> */}
          </div>
        </div>
      </div>

      {/* {showDrop ? <Dropdown /> : null} */}
    </div>
  );
}

export default Navbar;
